<div *ngIf="bShowLlamado">
    <app-llamado [medico]="medico" [piso]="piso" [box]="box" [paciente]="paciente"></app-llamado>
</div>
<div class="container-fluid" *ngIf="!bShowLlamado">
    <div class="row text-center box-container" *ngIf="bEmpty">
        <div class="col-12">
            <div class="row text-center  ml-4 mr-2 ">
                <!--items-->
                <div class="col-5 color-1  box-info text-left">
                    <div class="up-text">
                        <h2>Nombre Paciente</h2>
                    </div>
                    <div class="text-pers  display-5">
                        <i class="fas fa-user"></i>
                    </div>

                </div>
                <div class="col-5 color-2 text-left box-info">
                    <div class="up-text">
                        <h2>Nombre Médico</h2>
                    </div>
                    <div class="text-pers  display-5">
                        <i class="fas fa-user-md"></i>
                    </div>
                </div>
                <div class="col-2 color-3 box-info text-left">

                    <div class="text-pers  display-5 mt-3">
                        <i class="fas fa-paste"></i> BOX
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="row text-center box-container" *ngIf="!bEmpty">
        <div class="col-12" *ngFor="let paciente of pac">
            <div class="row text-center  ml-4 mr-2 ">
                <!--items-->
                <div class="col-5 color-1  box-info text-left">
                    <div class="up-text">
                        <h2>Nombre Paciente</h2>
                    </div>
                    <div class="text-pers  display-5">
                        <i class="fas fa-user"></i> {{paciente.paciente}}
                    </div>

                </div>
                <div class="col-5 color-2 text-left box-info">
                    <div class="up-text">
                        <h2>Nombre Médico</h2>
                    </div>
                    <div class="text-pers  display-5">
                        <i class="fas fa-user-md"></i> {{paciente.medico}}
                    </div>
                </div>
                <div class="col-2 color-3 box-info text-left">

                    <div class="text-pers  display-5 mt-3">
                        <i class="fas fa-paste"></i> BOX {{paciente.box}}
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
