import { AppConfig } from './../app.config';
import { TitleService } from './../services/title.service';
import { SignalRService } from './../services/signalr.service';
import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
const {Howl, Howler} = require('../../assets/howler/howler.js');
@Component({
  selector: 'app-llamado',
  templateUrl: './llamado.component.html',
  styleUrls: ['./llamado.component.css']
})
export class LlamadoComponent implements OnInit {
  @Input() public medico: string = '';
  @Input() public piso: string = '';
  @Input() public paciente: string = '';
  @Input() public box: string = '';

  TO: number = 3;
  constructor(
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private signalRService: SignalRService,
    private titleService: TitleService,
    private config: AppConfig
  ) { }

  ngOnInit() {
    this.TO = (this.config.get("screenCallTO") == undefined ? 3 : this.config.get("screenCallTO"));
   /*  console.log(this.medico);
    console.log(this.piso);
    console.log(this.paciente);
    console.log(this.box); */
    var sound = new Howl({
      src: '../../assets/mp3/Llamado.mp3',
      volume: 0.5,
    });
    sound.play();
     setTimeout(()=>{
        this.titleService.disableButton(false);
       },this.TO * 1000);

  }
}
